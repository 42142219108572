import { nanoid } from 'utils';

export const SECTIONS = {
  welcome: 'Welcome',
  trusted: 'Trusted by',
  about: 'About us',
  services: 'Services',
  products: 'Products',
  showcase: 'Showcase',
  team: 'Team',
  pricing: 'Pricing',
  testimonials: 'Testimonials',
  faqs: 'FAQs',
  contact: 'Contact'  
};

export const SECTIONS_CONFIG = {
  [SECTIONS.welcome]: { name: SECTIONS.welcome, type: 'generation' },
  [SECTIONS.trusted]: { name: SECTIONS.trusted, type: 'basic' },
  [SECTIONS.about]: { name: SECTIONS.about, type: 'generation' },
  [SECTIONS.services]: { name: SECTIONS.services, type: 'generation' },
  [SECTIONS.products]: { name: SECTIONS.products, type: 'generation' },
  [SECTIONS.showcase]: { name: SECTIONS.showcase, type: 'showcase' },
  [SECTIONS.team]: { name: SECTIONS.team, type: 'generation' },
  [SECTIONS.pricing]: { name: SECTIONS.pricing, type: 'generation' },
  [SECTIONS.testimonials]: { name: SECTIONS.testimonials, type: 'generation' },
  [SECTIONS.faqs]: { name: SECTIONS.faqs, type: 'generation' },
  [SECTIONS.contact]: { name: SECTIONS.contact, type: 'contact' },
};

export const SECTIONS_FULL_LIST = [SECTIONS.welcome, SECTIONS.trusted, SECTIONS.about, SECTIONS.services, SECTIONS.products, SECTIONS.showcase, SECTIONS.team, SECTIONS.pricing, SECTIONS.testimonials, SECTIONS.faqs, SECTIONS.contact];

export const INITIAL_SECTIONS_SELECTION = SECTIONS_FULL_LIST.map(name => ({
  name,
  selected: false
}));

export function initShowcaseItem() {
  return { id: nanoid(), files: [], title: '', subtitle: '', details: ''};
}

const SECTION_DATA_BY_TYPE = {
  'basic': {},
  'generation': { contentHistory: [], contentIdx: -1 },
  'contact': {
    fields: { email: '', phone: '', address: '', facebook: '', instagram: '', twitter: '' }
  },
  'showcase': {
    items: [...Array(2)].map(initShowcaseItem)
  }
}

export function initSectionsData(sectionsList) {
  return sectionsList.reduce((obj, name) => {
    const sectionConfig = SECTIONS_CONFIG[name];
    obj[name] = { ...sectionConfig, ...SECTION_DATA_BY_TYPE[sectionConfig.type]}
    return obj;
  }, {});
}

export function initSectionsStatus(sectionsList) {
  return sectionsList.reduce((obj, name) => {
    obj[name] = { checked: false, loading: false };
    return obj;
  }, {});
}

export function parseSubmissionSectionsData(sectionsData) {
  const parser = {
    'basic': ({ name, type }) => ({ name, type }),
    'generation': ({ name, type, contentHistory }) => ({ name, type, contentHistory }),
    'contact': ({ name, type, fields }) => ({ name, type, fields }),
    'showcase': ({ name, type, items }) => ({
      name, type, items: items.map(({ id, ...rest }) => ({ ...rest }))
    })
  };
  
  return Object.fromEntries(
    Object.entries(sectionsData).map(
      ([name, data]) => [name, parser[data.type](data)]
    )
  );
}