import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  selectSectionData, selectSectionStatus, selectIsActive, setActiveSection,
  addShowcaseItem, updateShowcaseItem, deleteShowcaseItem
} from 'appSlice';
import { SECTIONS } from 'config/sections';
import { createUniqueFilename } from 'utils';
import SectionContainer from 'components/Section/SectionContainer';
import ImageUpload from 'components/UI/ImageUpload';
import Input from 'components/UI/Input';
import Textarea from 'components/UI/Textarea';
import { ReactComponent as AddIcon } from 'assets/add.svg';
import { ReactComponent as CloseIcon } from 'assets/close.svg';

function ShowcaseSection({ name }) {
  const data = useSelector(state => selectSectionData(state, name));
  const status = useSelector(state => selectSectionStatus(state, name));
  const isActive = useSelector(state => selectIsActive(state, name));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function handleImageUpload(idx, files) {
    // Remove previous files from FILE_STORE
    for (const filename of data.items[idx].files) {
      delete window.FILE_STORE[SECTIONS.showcase][filename];
    }

    const newFilenames = [];
    const newFilesObj = {};
    for (const file of files) {
      const filename = createUniqueFilename(file.name);
      newFilenames.push(filename);
      newFilesObj[filename] = file;
    }
    window.FILE_STORE[SECTIONS.showcase] = {
      ...window.FILE_STORE[SECTIONS.showcase],
      ...newFilesObj
    };
    dispatch(updateShowcaseItem({ idx, key: 'files', value: newFilenames }));
  }

  return (
    <SectionContainer
      name={name}
      status={status}
      isActive={isActive}
      setActive={() =>  dispatch(setActiveSection(name))}
    >
      {isActive ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-8">
          {data.items.map((item, idx) => (
            <div key={item.id} className="relative max-w-[400px] mx-auto bg-white w-full border-2 border-gray-mlight p-4 rounded-2xl shadow-2xl">
              <button
                className="absolute top-1 right-1 flex justify-center items-center h-5 w-5 cursor-pointer hover:opacity-75"
                onClick={() => dispatch(deleteShowcaseItem(idx))}
              >
                <CloseIcon className="full-h full-w fill-gray-light" />
              </button>
              <div className="flex justify-center">
                <ImageUpload
                  multiple
                  numFiles={item.files.length}
                  onUpload={files => handleImageUpload(idx, files)}
                />
              </div>
              <div className="mt-4 space-y-3">
                <div>
                  <label className="block text-xs text-orange-main" htmlFor="title">{t('showcase.title')}</label>
                  <Input
                    className="input small"
                    name="title"
                    initialValue={item.title}
                    onEdit={value => dispatch(updateShowcaseItem({ idx, key: 'title', value }))}
                  />
                </div>
                <div>
                  <label className="block text-xs text-orange-main" htmlFor="subtitle">{t('showcase.subtitle')}</label>
                  <Input
                    className="input small"
                    name="subtitle"
                    initialValue={item.subtitle}
                    onEdit={value => dispatch(updateShowcaseItem({ idx, key: 'subtitle', value }))}
                  />
                </div>
                <div>
                  <label className="block text-xs text-orange-main" htmlFor="details">{t('showcase.details')}</label>
                  <Textarea
                    className="input small"
                    name="details"
                    rows="4"
                    initialValue={item.details}
                    onEdit={value => dispatch(updateShowcaseItem({ idx, key: 'details', value }))}
                  />
                </div>
              </div>
            </div>
          ))}
          <button
            className="justify-self-center sm:self-end sm:justify-self-auto flex justify-center items-center h-7 w-7 rounded-full bg-orange-main cursor-pointer hover:opacity-75"
            onClick={() => dispatch(addShowcaseItem())}
          >
            <AddIcon className="fill-white" />
          </button>
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-8">
          {data.items.slice(0, 3).map((item, idx) => (
            <div
              key={item.id}
              className="relative max-w-[400px] mx-auto bg-white w-full p-4 space-y-2 rounded-2xl shadow-lg cursor-pointer"
              onClick={() => dispatch(setActiveSection(name))}
            >
              <div className="input small complete h-7">
                {item.title}
              </div>
              <div className="input small complete h-7">
                {item.subtitle}
              </div>
              <div className="input small complete h-[88px] line-clamp-4">
                {item.details}
              </div>
            </div>
          ))}
        </div>
      )}
    </SectionContainer>
  );
}

export default ShowcaseSection;