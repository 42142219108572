import { useRef, useLayoutEffect } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from 'assets/pencil.svg';
import { ReactComponent as ErrorIcon } from 'assets/error.svg';

function Content({
  content, isLoading, isClickable, isComplete, isError, isEditing, contentEdit,
  onClick, onEdit, onEditBlur, onRegenerate
}) {
  const textareaRef = useRef(null);
  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (isEditing) {
      textareaRef.current.style.height = 'inherit';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [isEditing, contentEdit]);

  return (
    <div className="relative mb-2">
      {isEditing ? (
        <textarea
          className="block min-h-[150px] w-full text-gray-medium py-5 px-3 rounded-lg resize-none outline-none outline-orange-main"
          ref={textareaRef}
          autoFocus
          value={contentEdit}
          onChange={onEdit}
          onBlur={onEditBlur}
        />
      ) : (
      <>
        <div
          className={cx("block min-h-[150px] w-full bg-gray-xlight text-gray-medium py-5 px-3 rounded-lg shadow-md", {
            "bg-green-xlight": isComplete,
            "cursor-pointer": isClickable
          })}
          onClick={isClickable ? onClick : null}
        >
          {!isComplete && !isLoading && !isError && (
            <EditIcon className="absolute top-0.5 right-0.5 fill-orange-main"/>
          )}
          {isLoading && (
            <svg className="animate-spin absolute top-0.5 right-0.5 h-4 w-4 fill-orange-main stroke-orange-main" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" strokeWidth="4" fill="none"></circle>
              <path className="opacity-75" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          )}
          <div className={cx("whitespace-pre-line", {
            "after:animate-blink after:content-['▌'] after:ml-1": isLoading
          })}>
            {isError && (
              <div className="flex items-center mb-2 space-x-2 text-red-700 fill-red-700 text-sm">
                <ErrorIcon />
                <span>{t('mockup.generationError')}</span>
                <button
                  className="text-sm py-0.5 px-1.5 text-orange-main border border-orange-main rounded-md hover:opacity-75"
                  onClick={onRegenerate}
                >{t('mockup.retry')}</button>
              </div>
            )}
            {content}
          </div>
        </div>
      </>
      )}
    </div>
  );
}

export default Content;