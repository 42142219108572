import { createSlice } from '@reduxjs/toolkit';
import { RQ_STATUS } from 'config';
import { SECTIONS } from 'config/sections';
import {
  initSectionsData, initSectionsStatus, parseSubmissionSectionsData, initShowcaseItem
} from 'config/sections';
import apiService from 'apiService';
import i18next from 'i18n';

const initialState = {
  step: 0,
  email: '',
  description: '',
  graphics: [],
  otherSections: '',
  sectionsList: [],
  sectionsData: {},
  sectionsStatus: {},
  activeSection: null,
  submitStatus: RQ_STATUS.idle
}

/**************************
 * App Slice
 **************************/
export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    nextStep: (state) => {
      state.step += 1;
    },
    prevStep: (state) => {
      state.step -= 1; 
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setGraphics: (state, action) => {
      state.graphics = action.payload;
    },
    setOtherSections: (state, action) => {
      state.otherSections = action.payload;
    },
    setSections: (state, action) => {
      state.sectionsList = action.payload.sectionsList;
      state.sectionsData = action.payload.sectionsData;
      state.sectionsStatus = action.payload.sectionsStatus;
    },
    setActiveSection: (state, action) => {
      const name = action.payload;
      state.activeSection = name;
      if (!state.sectionsStatus[name].checked) {
        state.sectionsStatus[name].checked = true;
      }
    },
    setNoActiveSession: (state) => {
      state.activeSection = null;
    },
    updateSectionStatus: (state, action) => {
      const { name, status } = action.payload;
      state.sectionsStatus[name] = { ...state.sectionsStatus[name], ...status };
    },
    setSectionFiles: (state, action) => {
      const { name, files } = action.payload;
      state.sectionsData[name].files = files;
    },
    addSectionContent: (state, action) => {
      const { name, type, content, input = null } = action.payload;
      const sectionData = state.sectionsData[name];
      // Any contents above current 'contentIdx' will be deleted and overwritten
      sectionData.contentHistory.length = sectionData.contentIdx + 1;
      if (type === 'generation') {
        sectionData.contentHistory.push({ type, content, input, error: false });
      } else {
        sectionData.contentHistory.push({ type, content });
      }
      sectionData.contentIdx++;
    },
    appendTextToSectionContent: (state, action) => {
      const { name, text } = action.payload;
      const { contentHistory, contentIdx } = state.sectionsData[name];
      contentHistory[contentIdx].content += text;
    },
    editSectionContent: (state, action) => {
      const { name, content } = action.payload;
      const sectionData = state.sectionsData[name];
      if (sectionData.contentHistory.length > 0) {
        // Any contents above current 'contentIdx' will be deleted and overwritten
        sectionData.contentHistory.length = sectionData.contentIdx + 1;
        if (sectionData.contentHistory[sectionData.contentIdx].type === 'edit') {
          sectionData.contentHistory[sectionData.contentIdx].content = content
        } else {
          sectionData.contentHistory.push({ type: 'edit', content })
          sectionData.contentIdx++;
        }
      }
    },
    resetSectionContent: (state, action) => {
      const sectionData = state.sectionsData[action.payload];
      const currentContent = sectionData.contentHistory[sectionData.contentIdx];
      currentContent.error = false;
      currentContent.content = '';
    },
    setSectionContentError: (state, action) => {
      const { name, error } = action.payload;
      const sectionData = state.sectionsData[name];
      sectionData.contentHistory[sectionData.contentIdx].error = error;
    },
    undoSectionContent: (state, action) => {
      const sectionData = state.sectionsData[action.payload];
      if (sectionData.contentIdx > 0) {
        sectionData.contentIdx--;
      }
    },
    redoSectionContent: (state, action) => {
      const sectionData = state.sectionsData[action.payload];
      if (sectionData.contentIdx < sectionData.contentHistory.length - 1) {
        sectionData.contentIdx++;
      }
    },
    updateSectionField: (state, action) => {
      const { name, fieldName, fieldValue } = action.payload;
      state.sectionsData[name].fields[fieldName] = fieldValue;
    },
    addShowcaseItem: (state) => {
      state.sectionsData[SECTIONS.showcase].items.push(initShowcaseItem());
    },
    updateShowcaseItem: (state, action) => {
      const { idx, key, value } = action.payload;
      state.sectionsData[SECTIONS.showcase].items[idx][key] = value;
    },
    deleteShowcaseItem: (state, action) => {
      state.sectionsData[SECTIONS.showcase].items.splice(action.payload, 1);
    },
    setSubmitStatus: (state, action) => {
      state.submitStatus = action.payload;
    },
  }
});

export default appSlice.reducer;
export const {
  nextStep, prevStep, setEmail, setDescription, setGraphics, setOtherSections,
  setSections, setActiveSection, setNoActiveSession, updateSectionStatus,
  setSectionFiles, addSectionContent, appendTextToSectionContent, editSectionContent,
  resetSectionContent, setSectionContentError, undoSectionContent, redoSectionContent,
  updateSectionField, addShowcaseItem, updateShowcaseItem, deleteShowcaseItem, setSubmitStatus
} = appSlice.actions;


/**************************
 * Selector Functions
 **************************/
export const selectStep = state => state.app.step;
export const selectEmail = state => state.app.email;
export const selectDescription = state => state.app.description;
export const selectGraphics = state => state.app.graphics;
export const selectOtherSections = state => state.app.otherSections;
export const selectSectionsList = state => state.app.sectionsList;
export const selectSectionsData = state => state.app.sectionsData;
export const selectSectionsStatus = state => state.app.sectionsStatus;
export const selectSectionData = (state, name) => state.app.sectionsData[name];
export const selectSectionStatus = (state, name) => state.app.sectionsStatus[name];
export const selectActiveSection = state => state.app.activeSection;
export const selectIsActive = (state, name) => state.app.activeSection === name;
export const selectSubmitStatus = state => state.app.submitStatus;


/**************************
 * Async Action Creators
 **************************/
/* STREAMING */
export const generateSectionContent = (name) => async (dispatch, getState) => {
  const data = {
    type: 'generation',
    section: name,
    description: selectDescription(getState()),
    language: i18next.languages[0]
  };
  const onMessage = content => dispatch(appendTextToSectionContent({ name, text: content }));
  const onComplete = () => dispatch(updateSectionStatus({ name, status: { loading: false } }));
  const onError = () => dispatch(setSectionContentError({ name, error: true }));
  
  dispatch(updateSectionStatus({ name, status: { loading: true } }));
  dispatch(addSectionContent({ name, type: 'generation', content: '' }));
  apiService.generateContentStream(data, onMessage, onComplete, onError);
};

export const updateSectionContent = (name, input) => async (dispatch, getState) => {
  const { contentHistory, contentIdx } = selectSectionData(getState(), name);
  if (contentHistory.length === 0) {
    return;
  }
  
  const content = contentHistory[contentIdx].content;
  const data = {
    type: 'update',
    section: name,
    content,
    input,
    language: i18next.languages[0]
  };
  const onMessage = content => dispatch(appendTextToSectionContent({ name, text: content }));
  const onComplete = () => dispatch(updateSectionStatus({ name, status: { loading: false } }));
  const onError = () => dispatch(setSectionContentError({ name, error: true }));
  
  dispatch(updateSectionStatus({ name, status: { loading: true } }));
  dispatch(addSectionContent({ name, type: 'generation', input, content: '' }));
  apiService.generateContentStream(data, onMessage, onComplete, onError);
};

export const regenerateSectionContent = (name) => async (dispatch, getState) => {
  const state = getState();
  const { contentHistory, contentIdx } = selectSectionData(state, name);
  let data;

  if (contentIdx === 0) {
    data = {
      type: 'generation',
      section: name,
      description: selectDescription(getState()),
      language: i18next.languages[0]
    };
  } else {
    data = {
      type: 'update',
      section: name,
      content: contentHistory[contentIdx - 1].content,
      input: contentHistory[contentIdx].input,
      language: i18next.languages[0]
    };
  }

  const onMessage = content => dispatch(appendTextToSectionContent({ name, text: content }));
  const onComplete = () => dispatch(updateSectionStatus({ name, status: { loading: false } }));
  const onError = () => dispatch(setSectionContentError({ name, error: true }));
  
  dispatch(resetSectionContent(name));
  dispatch(updateSectionStatus({ name, status: { loading: true } }));
  apiService.generateContentStream(data, onMessage, onComplete, onError);
};

export const startMockup = (sectionsList) => async (dispatch) => {
  const sectionsData = initSectionsData(sectionsList);
  const sectionsStatus = initSectionsStatus(sectionsList);
  dispatch(setSections({ sectionsList, sectionsData, sectionsStatus }));
  dispatch(nextStep());
  if (sectionsData[sectionsList[0]].type === 'generation') {
    dispatch(generateSectionContent(sectionsList[0]));
  }
  dispatch(setActiveSection(sectionsList[0]));
};

export const submitWebsiteRequest = () => async (dispatch, getState) => {
  const {
    email, description, graphics, sectionsList, sectionsData, submitStatus
  } = getState().app;

  if (submitStatus === RQ_STATUS.sending || submitStatus === RQ_STATUS.success) {
    return;
  }

  dispatch(setSubmitStatus(RQ_STATUS.sending));

  const submissionData = {
    email,
    description,
    graphics,
    sectionsList,
    sectionsData: parseSubmissionSectionsData(sectionsData)
  }

  const formData = new FormData();
  
  formData.append('data', JSON.stringify(submissionData));

  for (const key of Object.values(window.FILE_STORE)) {
    for (const [filename, file] of Object.entries(key)) {
      formData.append(filename, file, filename);
    }
  }

  try {
    await apiService.submitWebsiteRequest(formData);
    dispatch(setSubmitStatus(RQ_STATUS.success));
  } catch(error) {
    console.log(error);
    dispatch(setSubmitStatus(RQ_STATUS.error));
  }
};