import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  selectSectionData, selectSectionStatus, selectIsActive, setActiveSection,
  updateSectionField
} from 'appSlice';
import SectionContainer from 'components/Section/SectionContainer';
import Input from 'components/UI/Input';

function ContactSection({ name }) {
  const data = useSelector(state => selectSectionData(state, name));
  const status = useSelector(state => selectSectionStatus(state, name));
  const isActive = useSelector(state => selectIsActive(state, name));
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const isComplete = !isActive && status.checked;

  function setActive() {
    dispatch(setActiveSection(name));
  }

  return (
    <SectionContainer
      name={name}
      status={status}
      isActive={isActive}
      setActive={setActive}
    >
      {status.checked && (
        <div
          className={isComplete ? "cursor-pointer" : ""}
          onClick={isComplete ? setActive : null}
        >
          <ul className="w-full space-y-4">
            {(isActive || data.fields.email) && (
              <li className="flex items-center space-x-4">
                <svg className={cx("input-icon", { "complete": isComplete })} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                  <path fill="none" d="M0 0h24v24H0z"/><path d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm9.06 8.683L5.648 6.238 4.353 7.762l7.72 6.555 7.581-6.56-1.308-1.513-6.285 5.439z"/>
                </svg>
                {isActive ? (
                  <Input
                    className={cx("input", { "complete": isComplete })}
                    name="email"
                    placeholder={isActive ? t('contact.email') : ""}
                    initialValue={data.fields.email}
                    onEdit={value => dispatch(updateSectionField({
                      name, fieldName: "email", fieldValue: value
                    }))}
                  />
                ) : (
                  <div className={cx("input", { "complete": isComplete })}>
                    {data.fields.email}
                  </div>
                )}
              </li>
            )}
            {(isActive || data.fields.phone) && (
              <li className="flex items-center space-x-4">
                <svg className={cx("input-icon", { "complete": isComplete })} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                  <path fill="none" d="M0 0h24v24H0z"/><path d="M21 16.42v3.536a1 1 0 0 1-.93.998c-.437.03-.794.046-1.07.046-8.837 0-16-7.163-16-16 0-.276.015-.633.046-1.07A1 1 0 0 1 4.044 3H7.58a.5.5 0 0 1 .498.45c.023.23.044.413.064.552A13.901 13.901 0 0 0 9.35 8.003c.095.2.033.439-.147.567l-2.158 1.542a13.047 13.047 0 0 0 6.844 6.844l1.54-2.154a.462.462 0 0 1 .573-.149 13.901 13.901 0 0 0 4 1.205c.139.02.322.042.55.064a.5.5 0 0 1 .449.498z"/>
                </svg>
                {isActive ? (
                  <Input
                    className={cx("input", { "complete": isComplete })}
                    name="phone"
                    placeholder={isActive ? t('contact.phone') : ""}
                    initialValue={data.fields.phone}
                    onEdit={value => dispatch(updateSectionField({
                      name, fieldName: "phone", fieldValue: value
                    }))}
                  />
                ) : (
                  <div className={cx("input", { "complete": isComplete })}>
                    {data.fields.phone}
                  </div>
                )}
              </li>
            )}
            {(isActive || data.fields.address) && (
              <li className="flex items-center space-x-4">
                <svg className={cx("input-icon", { "complete": isComplete })} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                  <path fill="none" d="M0 0h24v24H0z"/><path d="M18.364 17.364L12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0zM12 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"/>
                </svg>
                {isActive ? (
                  <Input
                    className={cx("input", { "complete": isComplete })}
                    name="address"
                    placeholder={isActive ? t('contact.address') : ""}
                    initialValue={data.fields.address}
                    onEdit={value => dispatch(updateSectionField({
                      name, fieldName: "address", fieldValue: value
                    }))}
                  />
                ) : (
                  <div className={cx("input", { "complete": isComplete })}>
                    {data.fields.address}
                  </div>
                )}
              </li>
            )}
            {(isActive || data.fields.facebook) && (
              <li className="flex items-center space-x-4">
                <svg className={cx("input-icon", { "complete": isComplete })} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                  <path fill="none" d="M0 0h24v24H0z"/><path d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z"/>
                </svg>
                {isActive ? (
                  <Input
                    className={cx("input", { "complete": isComplete })}
                    name="facebook"
                    placeholder={isActive ? t('contact.facebook') : ""}
                    initialValue={data.fields.facebook}
                    onEdit={value => dispatch(updateSectionField({
                      name, fieldName: "facebook", fieldValue: value
                    }))}
                  />
                ) : (
                  <div className={cx("input", { "complete": isComplete })}>
                    {data.fields.facebook}
                  </div>
                )}
              </li>
            )}
            {(isActive || data.fields.instagram) && (
              <li className="flex items-center space-x-4">
                <svg className={cx("input-icon", { "complete": isComplete })} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                  <path fill="none" d="M0 0h24v24H0z"/><path d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z"/>
                </svg>
                {isActive ? (
                  <Input
                    className={cx("input", { "complete": isComplete })}
                    name="instagram"
                    placeholder={isActive ? t('contact.instagram') : ""}
                    initialValue={data.fields.instagram}
                    onEdit={value => dispatch(updateSectionField({
                      name, fieldName: "instagram", fieldValue: value
                    }))}
                  />
                ) : (
                  <div className={cx("input", { "complete": isComplete })}>
                    {data.fields.instagram}
                  </div>
                )}
              </li>
            )}
            {(isActive || data.fields.twitter) && (
              <li className="flex items-center space-x-4">
                <svg className={cx("input-icon", { "complete": isComplete })} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                  <path fill="none" d="M0 0h24v24H0z"/><path d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z"/>
                </svg>
                {isActive ? (
                  <Input
                    className={cx("input", { "complete": isComplete })}
                    name="twitter"
                    placeholder={isActive ? t('contact.twitter') : ""}
                    initialValue={data.fields.twitter}
                    onEdit={value => dispatch(updateSectionField({
                      name, fieldName: "twitter", fieldValue: value
                    }))}
                  />
                ) : (
                  <div className={cx("input", { "complete": isComplete })}>
                    {data.fields.twitter}
                  </div>
                )}
              </li>
            )}
          </ul>
        </div>
      )}
    </SectionContainer>
  );
}

export default ContactSection;