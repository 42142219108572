import { useState, forwardRef } from 'react';

function Textarea({ className, initialValue, onEdit, ...rest }, ref) {
  const [value, setValue] = useState(initialValue);

  return (
    <textarea
      className={className}
      ref={ref}
      value={value}
      onChange={e => setValue(e.target.value)}
      onBlur={() => onEdit(value)}
      {...rest}
    />
  );
}

export default forwardRef(Textarea);