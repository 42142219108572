import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  selectStep
} from 'appSlice';
import apiService from 'apiService';
import Header from 'components/Header';
import Setup from 'components/Setup';
import Mockup from 'components/Mockup';
import Submit from 'components/Submit';

// Define global variable to store file uploads
window.FILE_STORE = {};

function App() {
  const step = useSelector(selectStep);
  
  useEffect(() => apiService.startUp(), []);

  return (
    <div>
      <Header />
      {(step === 0) ? <Setup />
      : (step === 1) ? <Mockup />
      : (step === 2) ? <Submit /> : null}
    </div>
  );
}

export default App;
