import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { ReactComponent as CheckIcon } from 'assets/check.svg';
import { ReactComponent as ClickIcon } from 'assets/cursor-rays.svg';

function SectionContainer({ name, status, isActive, setActive, children }) {
  const $sectionContainer = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (status.loading) {
      const { top, bottom } = $sectionContainer.current.getBoundingClientRect();
      if (bottom > window.innerHeight || top < 0) {
        $sectionContainer.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [status.loading]);

  const isClickable = !isActive && !status.loading;
  const isComplete = !isActive && status.checked;

  return (
    <div className="section-container" ref={$sectionContainer}>
      <div className="section-header">
        <div className="flex justify-between items-start">
          <div
            className={cx("flex items-start", { "cursor-pointer hover:opacity-75": isClickable })}
            onClick={isClickable ? setActive : null}
          >
            <h3 className={cx("section-title", {
              "text-green-main": isComplete,
              "text-gray-blue-light": !status.checked
            })}>
              {t(`sections.${name}`)}
            </h3>
            {isComplete && (
              <CheckIcon className="h-5 ml-3 fill-green-main" />
            )}
          </div>
        </div>
        {isActive && (
          <p className="section-subtitle">{t(`sectionsSubtitles.${name}`)}</p>
        )}
      </div>
      {status.checked ? children : (
        <div
          className="h-[150px] w-full flex justify-center items-center border-2 border-dashed border-gray-blue-ligh text-sm text-gray-blue-light font-light rounded-lg cursor-pointer hover:opacity-75"
          onClick={setActive}
        >
          <ClickIcon className="" />
        </div>
      )}
    </div>
  );
}

export default SectionContainer;