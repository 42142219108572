import { useState, forwardRef } from 'react';

function Input({ className, initialValue, onEdit, ...rest }, ref) {
  const [value, setValue] = useState(initialValue);

  return (
    <input
      className={className}
      ref={ref}
      value={value}
      onChange={e => setValue(e.target.value)}
      onBlur={() => onEdit(value)}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          onEdit(value);
        }
      }}
      {...rest}
    />
  );
}

export default forwardRef(Input);