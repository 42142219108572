import { useSelector, useDispatch } from 'react-redux';
import {
  selectSectionData, selectSectionStatus, selectIsActive, setActiveSection,
  generateSectionContent
} from 'appSlice';
import SectionContainer from 'components/Section/SectionContainer';
import ContentGenerator from 'components/ContentGenerator/ContentGenerator';

function GenerationSection({ name }) {
  const data = useSelector(state => selectSectionData(state, name));
  const status = useSelector(state => selectSectionStatus(state, name));
  const isActive = useSelector(state => selectIsActive(state, name));
  const dispatch = useDispatch();

  function setActive() {
    if (!status.checked) {
      dispatch(generateSectionContent(name));
    }
    dispatch(setActiveSection(name));
  }

  return (
    <SectionContainer
      name={name}
      status={status}
      isActive={isActive}
      setActive={setActive}
    >
      <ContentGenerator
        name={name}
        data={data}
        content={data.contentHistory[data.contentIdx]?.content || ''}
        status={status}
        isActive={isActive}
        setActive={setActive}
      />
    </SectionContainer>
  )
}

export default GenerationSection;