import { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import {
  selectDescription, selectGraphics, setDescription, setGraphics, startMockup
} from 'appSlice';
import { INITIAL_SECTIONS_SELECTION } from 'config/sections';
import { createUniqueFilename } from 'utils';
import Textarea from 'components/UI/Textarea';
import FileUpload from 'components/UI/FileUpload';
import abstractBg from 'assets/abstract-bg.jpg';

function Setup() {
  const description = useSelector(selectDescription);
  const graphics = useSelector(selectGraphics);
  const dispatch = useDispatch();

  const [sectionsListSelection, setSectionsListSelection] = useState(INITIAL_SECTIONS_SELECTION);
  const [errors, setErrors] = useState([]);
  const $description = useRef(null);
  const { t, i18n } = useTranslation();

  function handleGraphicsUpload(files) {
    const newFilenames = [];
    const newFilesObj = {};
    for (const file of files) {
      const filename = createUniqueFilename(file.name);
      newFilesObj[filename] = file;
      newFilenames.push(filename);
    }
    window.FILE_STORE['graphics'] = newFilesObj;
    dispatch(setGraphics(newFilenames));
  }

  function toggleSection(name) {
    setSectionsListSelection(sectionsListSelection.map(s => {
      if (s.name === name) {
        return { ...s, selected: !s.selected };
      } else {
        return s;
      }
    }));
  }

  function confirmSetup() {
    const newErrors = [];
    if (description === '') {
      newErrors.push('description');
      $description.current.focus();
    }
    if (!Object.values(sectionsListSelection).some(s => s.selected)) {
      newErrors.push('sections');
    }
    if (newErrors.length > 0) {
      setErrors(newErrors);
    } else {
      const sectionsList = sectionsListSelection.filter(s => s.selected).map(s => s.name);
      dispatch(startMockup(sectionsList));
    }
  }

  return (
    <div className="container py-10">
      <div className="w-full max-w-[800px] mx-auto">
        <div className="relative h-32 mb-10 flex justify-center items-center">
          <img className="absolute h-full w-full object-cover object-center" src={abstractBg} alt='background' />
          <h1 className="text-2xl md:text-3xl text-gray-main font-semibold text-center z-10">{t('setup.header')}</h1>
        </div>

        <div className="step-header">
          <span className="step-header-box">{t('setup.stepBox')}</span>
          <span>{t('setup.stepText')}</span>
        </div>

        {/* LANGUAGE */}
        <div className="section-container">
          <div className="section-header">
            <h3 className="section-title">{t('setup.languageTitle')}</h3>
          </div>
          <select
            className="select"
            value={i18n.languages[0]}
            onChange={e => i18n.changeLanguage(e.target.value)}
          >
            <option value="en">English</option>
            <option value="es">Español</option>
          </select>
        </div>
        
        {/* DESCRIPTION */}
        <div className="section-container">
          <div className="section-header">
            <h3 className="section-title">{t('setup.descriptionTitle')}</h3>
            <div className="section-subtitle">
              <p>{t('setup.descriptionSubtitle1')}</p>
              <p>{t('setup.descriptionSubtitle2')}</p>
            </div>
          </div>
          {errors.includes('description') && (
            <p className="text-red-700 text-sm">{t('setup.descriptionError')}</p>
          )}
          <Textarea
            className="input-box"
            rows="10"
            ref={$description}
            initialValue={description}
            onEdit={value => dispatch(setDescription(value))}
          />
        </div>

        {/* GRAPHIC CONTENT */}
        <div className="section-container">
          <div className="section-header">
            <h3 className="section-title">{t('setup.graphicContentTitle')}</h3>
            <div className="section-subtitle">
              <p>{t('setup.graphicContentSubtitle')}</p>
            </div>
          </div>
          <FileUpload
            numFiles={graphics.length}
            onUpload={handleGraphicsUpload}
          />
        </div>

        {/* SECTIONS */}
        <div className="section-container">
          <div className="section-header">
            <h3 className="section-title">{t('setup.sectionsTitle')}</h3>
          </div>
          {errors.includes('sections') && (
            <p className="text-red-700 text-sm">{t('setup.sectionsError')}</p>
          )}
          <div className="max-w-[600px] flex flex-wrap items-center gap-8 mt-9">
            {sectionsListSelection.map(({ name, selected }) => (
              <span
                key={name}
                className={cx("bg-gray-xlight text-gray-main py-2 px-4 rounded-xl shadow cursor-pointer hover:shadow-lg", {
                  "bg-green-light": selected
                })}
                onClick={() => toggleSection(name)}
              >
                {t(`sections.${name}`)}
              </span>
            ))}
          </div>
          {/* <div className="mt-12">
            <p className="text-gray-medium-light font-light mb-1">{t('setup.otherSections')}</p>
            <input
              className="input"
              value={otherSections}
              onChange={e => dispatch(setOtherSections(e.target.value))}
            />
          </div> */}
        </div>

        <div className="mb-16 flex justify-end">
          <button
            className="button orange"
            onClick={confirmSetup}
          >{t('setup.build')}</button>
        </div>
      </div>
    </div>
  );
}

export default Setup;