import { useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectSectionsList, nextStep, prevStep, setNoActiveSession } from 'appSlice';
import { SECTIONS_CONFIG } from 'config/sections';
import BasicSection from 'components/Section/BasicSection';
import GenerationSection from 'components/Section/GenerationSection';
import ContactSection from 'components/Section/ContactSection';
import ShowcaseSection from 'components/Section/ShowcaseSection';

const SECTIONS_COMPONENTS = {
  basic: BasicSection,
  generation: GenerationSection,
  contact: ContactSection,
  showcase: ShowcaseSection
};

function Mockup() {
  const sectionsList = useSelector(selectSectionsList);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  useLayoutEffect(() => window.scrollTo(0, 0), []);

  function handleNext() {
    dispatch(nextStep());
    dispatch(setNoActiveSession());
  }

  return (
    <div className="container py-10">
      <div className="w-full max-w-[800px] mx-auto">
        <div className="mb-6">
          <button
            className="text-orange-main hover:opacity-75"
            onClick={() => dispatch(prevStep())}
          >{t('mockup.back')}</button>
        </div>
        <div className="step-header">
          <span className="step-header-box">{t('mockup.stepBox')}</span>
          <span>{t('mockup.stepText')}</span>
        </div>

        {sectionsList.map(name => {
          const SectionComponent = SECTIONS_COMPONENTS[SECTIONS_CONFIG[name].type];
          return <SectionComponent key={name} name={name} />;
        })}
        
        <div className="mb-16 flex justify-end">
          <button
            className="button orange"
            onClick={handleNext}
          >{t('mockup.finish')}</button>
        </div>
      </div>
    </div>
  );
}

export default Mockup;