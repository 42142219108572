import { useRef, useEffect } from 'react';

function useOutsideClick(isActive, callback) {
  const ref = useRef(null);

  useEffect(() => {
    if (isActive) {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          event.stopPropagation();
          callback();
        }
      };
      document.addEventListener('click', handleClick, true);
  
      return () => {
        document.removeEventListener('click', handleClick, true);
      };
    }
  }, [isActive, callback]);

  return ref;
}

export default useOutsideClick;