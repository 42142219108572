import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FileUploadIcon } from 'assets/file-upload.svg';

function ImageUpload({ numFiles, onUpload }) {
  const { t } = useTranslation();
  const $input = useRef(null);
  
  return (
    <div className="flex items-end gap-3">
      <div
        className="h-16 w-64 flex justify-center items-center color-orange-main border border-dashed border-orange-main rounded-xl cursor-pointer opacity-75 hover:opacity-100"
        onClick={() => $input.current.click()}
      >
        <FileUploadIcon className="h-1/2 w-1/2 fill-orange-main cursor-pointer" />
        <input
          className="hidden"
          ref={$input}
          type="file"
          multiple
          onChange={e => onUpload(Array.from(e.target.files))}
        />
      </div>
      {numFiles > 0 && (
        <span className="text-sm text-orange-main font-thin">
          {numFiles}{numFiles === 1 ? t('setup.fileSelected') : t('setup.filesSelected')}
        </span>
      )}
    </div>
  );
}

export default ImageUpload;