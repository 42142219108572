import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import {
  selectEmail, selectSubmitStatus, setEmail, submitWebsiteRequest, prevStep
} from 'appSlice';
import Input from 'components/UI/Input';
import { ReactComponent as CheckIcon } from 'assets/check.svg';
import { ReactComponent as ErrorIcon } from 'assets/error.svg';

function Submit() {
  const email = useSelector(selectEmail);
  const submitStatus = useSelector(selectSubmitStatus);
  const dispatch = useDispatch();
  const $emailInput = useRef(null);
  const { t } = useTranslation();

  useEffect(() => $emailInput.current.focus(), []);

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(submitWebsiteRequest());
  }
  
  return (
    <div className="container py-10">
      <div className="w-full max-w-[800px] mx-auto">
        <div className="mb-6">
          <button
            className="text-orange-main hover:opacity-75"
            onClick={() => dispatch(prevStep())}
          >{t('submit.back')}</button>
        </div>
      
        <div className="max-w-[450px] mx-auto">
          <p className="mb-6 text-green-main text-xl font-semibold text-center">
            {t('submit.header')}
          </p>
          <div className="mb-6 text-center text-gray-medium font-semibold">
            <p>{t('submit.text1')}</p>
            <p>{t('submit.text2')}</p>
          </div>
          <form onSubmit={handleSubmit}>
            <Input
              className="input mb-6"
              type="email"
              placeholder="Email"
              required
              ref={$emailInput}
              initialValue={email}
              onEdit={value => dispatch(setEmail(value))}
            />
            <button
              type='submit'
              className={cx("block mb-6 mx-auto button orange", {
                "sending": submitStatus === 'sending'
              })}
              disabled={submitStatus === 'success'}
            >{t('submit.send')}</button>
          </form>
          {submitStatus === 'success' && (
            <div className="flex items-center space-x-2 text-green-main fill-green-main">
              <CheckIcon className="shrink-0" />
              <div>
                <p>{t('submit.success1')}</p>
                <p>{t('submit.success2')}</p>
              </div>
            </div>
          )}
          {submitStatus === 'error' && (
            <div className="flex items-center space-x-2 text-red-700 fill-red-700">
              <ErrorIcon className="shrink-0" />
              <div>
                <p>{t('submit.error1')}</p>
                <p>{t('submit.error2')}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Submit;