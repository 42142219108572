import logo from 'assets/logo.svg';

function Header() {
  return (
    <header className="bg-orange-light">
      <div className="container">
        <div className="h-14 w-full max-w-[800px] mx-auto flex items-center gap-1">
          <div className="h-10 w-10 bg-white flex justify-center items-center rounded-full">
            <img className="h-10 w-10 ml-2 drop-shadow-md" src={logo} alt="logo"/>
          </div>
          <span className="text-orange-main -ml-0.5 text-lg md:text-xl font-semibold">Dreamyourweb</span>
        </div>
      </div>
    </header>
  );
}

export default Header;