import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { ReactComponent as UndoIcon } from 'assets/undo.svg';
import { ReactComponent as RedoIcon } from 'assets/redo.svg';

function Controls({
  isEditing, isLoading, editControlsRef, isFirstContent, isLastContent,
  onEditSave, onEditCancel, onUndo, onRedo
}) {
  const { t } = useTranslation();

  return (
    <div className={cx("h-8 px-2", { "invisible": isLoading })}>
      {isEditing ? (
        <div tabIndex='-1' className="flex justify-end space-x-14" ref={editControlsRef}>
          <button
            className="text-orange-main hover:opacity-75"
            onClick={onEditCancel}
          >{t('mockup.cancel')}</button>
          <button
            className="text-orange-main hover:opacity-75"
            onClick={onEditSave}
          >{t('mockup.saveChanges')}</button>
        </div>
      ) : (
        <div className="flex justify-between items-center">
          <button onClick={!isFirstContent ? onUndo : null} >
            <UndoIcon className={cx("fill-blue-light opacity-70 hover:opacity-100", {
              "fill-gray-mlight hover:opacity-70 cursor-auto": isFirstContent
            })} />
          </button>
          <button onClick={!isLastContent ? onRedo : null} >
            <RedoIcon className={cx("fill-blue-light opacity-70 hover:opacity-100", {
              "fill-gray-mlight hover:opacity-70 cursor-auto": isLastContent
            })} />
          </button>
        </div>
      )}
    </div>
  );
}

export default Controls;