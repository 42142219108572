import axios from 'axios';
import { SSE } from 'sse.js';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*'
  }
});

let generationsUrl = '/generations';
let generationsStreamUrl = process.env.REACT_APP_API_BASE_URL + '/generations/stream';
if (process.env.REACT_APP_FAKE_GENERATIONS === 'true') {
  generationsUrl =  '/fake-generations';
  generationsStreamUrl =  process.env.REACT_APP_API_BASE_URL + '/fake-generations/stream';
}

function startUp() {
  api.get('/');
}

async function generateContent(data) {
  const res = await api.post(generationsUrl, data);
  return res.data;
}

async function generateContentStream(data, onMessage, onComplete, onError) {
  const source = new SSE(generationsStreamUrl, {
    headers: { 'Content-Type': 'application/json' },
    payload: JSON.stringify(data)
  });
  source.addEventListener('message', e => {
    try {
      const data = JSON.parse(e.data);
      onMessage(data.content);
    } catch {}
  });
  source.addEventListener('readystatechange', e => {
    if (e.readyState === 2) {
      onComplete();
    }
  });
  source.addEventListener('error', e => {
    onError();
  });
  source.stream();
}

async function submitWebsiteRequest(data) {
  const res = await api.post('/submissions', data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  return res.data;
}

const service = {
  startUp,
  generateContent,
  generateContentStream,
  submitWebsiteRequest
}; 

export default service;