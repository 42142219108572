import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useOutsideClick from 'hooks/useOutsideClick';
import { ReactComponent as SendIcon } from 'assets/send.svg';
import { ReactComponent as HelpIcon } from 'assets/help.svg';

function UserInput({ onInput }) {
  const [value, setValue] = useState('');
  const [showHelp, setShowHelp] = useState(false);
  const { t } = useTranslation();

  const handleModalClickOutside = useCallback(() => {
    setShowHelp(false);
  }, []);

  const modalRef = useOutsideClick(showHelp, handleModalClickOutside);

  function handleKeyDown(e) {
    if(e.keyCode === 13 && e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  }

  function handleSubmit() {
    if (value) {
      onInput(value);
      setValue('');
    }
  }

  return (
    <div className="relative w-full flex-1 mt-2">
      <textarea
        className="block w-full bg-white border-2 border-gray-light p-2 pr-7 rounded-lg resize-none placeholder:text-gray-light focus:outline-none focus:border-orange-main"
        rows="2"
        placeholder={t('mockup.userInput')}
        value={value}
        onChange={e => setValue(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <button
        className="absolute top-1/2 right-1 transform -translate-y-1/2 p-1 rounded-md hover:bg-gray-mlight"
        onClick={handleSubmit}
      >
        <SendIcon />
      </button>
      <button
        className="absolute -bottom-6 left-0 rounded-full opacity-70 hover:opacity-100"
        onClick={e => setShowHelp(prev => !prev)}
      >
        <HelpIcon className="fill-orange-main" />
      </button>
      {showHelp && (
        <div
          ref={modalRef}
          className="absolute top-[calc(100%+27px)] left-0 w-full max-w-[500px] bg-blue-xlight border-2 border-blue-light p-3 shadow-lg rounded-lg z-50"
        >
          <div className="text-sm text-gray-medium space-y-1">
            <p className="font-semibold">{t('mockup.userInputHelpIntro')}</p>
            <ul className="list-disc list-outside ml-6">
              {t('mockup.userInputHelpExamples', { returnObjects: true }).map(example => (
                <li class="">{example}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserInput;