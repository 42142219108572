import { useRef } from 'react';
import { ReactComponent as ImageUploadIcon } from 'assets/image-upload.svg';

function ImageUpload({ multiple = false, numFiles, onUpload }) {
  const $input = useRef(null);
  
  return (
    <div className="flex items-center">
      <span className="w-3 text-blue-main font-semibold">
        {numFiles > 0 && numFiles}
      </span>
      <ImageUploadIcon
        className="cursor-pointer opacity-70 hover:opacity-100"
        onClick={() => $input.current.click()}
      />
      <input
        className="hidden"
        ref={$input}
        type="file"
        multiple={!!multiple}
        onChange={e => onUpload(Array.from(e.target.files))}
      />
    </div>
  );
}

export default ImageUpload;