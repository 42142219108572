import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  updateSectionContent, editSectionContent, undoSectionContent, redoSectionContent,
  regenerateSectionContent
} from 'appSlice';
import Content from 'components/ContentGenerator/Content';
import Controls from 'components/ContentGenerator/Controls';
import UserInput from 'components/ContentGenerator/UserInput';

function ContentGenerator({ name, data, status, isActive, setActive }) {
  const [isEditing, setIsEditing] = useState(false);
  const [contentEdit, setContentEdit] = useState('');
  const dispatch = useDispatch();
  const editControlsRef = useRef(null);

  const { content = '', feedback, error } = data.contentHistory[data.contentIdx] || {};

  function handleContentClick() {
    if (isActive) {
      setContentEdit(content);
      setIsEditing(true);
    } else {
      setActive();
    }
  }

  function handleEditSave() {
    if (contentEdit !== content) {
      dispatch(editSectionContent({ name, content: contentEdit }));
    }
    setIsEditing(false);
  }
  
  function handleEditBlur(e) {
    if (!editControlsRef.current.contains(e.relatedTarget)) {
      handleEditSave();
    }
  }

  function handleUserInput(input) {
    if (!status.loading) {
      dispatch(updateSectionContent(name, input));
    }
  }

  function handleRegenerate() {
    dispatch(regenerateSectionContent(name));
  }
  
  return status.checked ? (
    <div className="relative max-w-[800px]">
      <Content
        content={content}
        isLoading={status.loading}
        isClickable={!error && (!isActive || !status.loading)}
        isComplete={!isActive && status.checked}
        isError={error}
        isEditing={isEditing}
        contentEdit={contentEdit}
        onClick={handleContentClick}
        onEdit={e => setContentEdit(e.target.value)}
        onEditBlur={handleEditBlur}
        onRegenerate={handleRegenerate}
      />
      {isActive && (
        <>
          <Controls
            isEditing={isEditing}
            isLoading={status.loading}
            feedback={feedback}
            editControlsRef={editControlsRef}
            isFirstContent={data.contentIdx === 0}
            isLastContent={data.contentIdx === data.contentHistory.length - 1}
            onEditSave={handleEditSave}
            onEditCancel={() => setIsEditing(false)}
            onUndo={() => dispatch(undoSectionContent(name))}
            onRedo={() => dispatch(redoSectionContent(name))}
          />
          <UserInput onInput={handleUserInput} />
        </>
      )}
    </div>
  ) : null;
}

export default ContentGenerator;