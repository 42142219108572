import { useSelector, useDispatch } from 'react-redux';
import { selectSectionStatus, selectIsActive, setActiveSection } from 'appSlice';
import SectionContainer from 'components/Section/SectionContainer';

function BasicSection({ name }) {
  const status = useSelector(state => selectSectionStatus(state, name));
  const isActive = useSelector(state => selectIsActive(state, name));
  const dispatch = useDispatch();

  return (
    <SectionContainer
      name={name}
      status={status}
      isActive={isActive}
      setActive={() =>  dispatch(setActiveSection(name))}
    />
  );
}

export default BasicSection;